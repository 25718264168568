<template>
	<WDialog
		v-if="canEditUniqueNumber"
		v-model="showDialog"
		:max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'"
		:title="$t('documents.edit_number')"
		@ready="onDialogReady"
		@close="close()"
	>
		<v-layout row>
			<v-text-field ref="uniqueNumberInput" v-model.trim="uniqueNumber" autofocus color="primary" :label="$t('documents.document_number')" maxlength="191" />
		</v-layout>
		<template v-slot:actions>
			<v-spacer />
			<WButton flat icon="save" @click="save()">{{ $t('actions.save') }}</WButton>
		</template>
	</WDialog>
</template>

<script>
import { mapState } from 'vuex'
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'EditDescriptionDialog',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: true,
			validator: function (value) {
				let result = true
				if (!value.hasOwnProperty('type')) {
					result = 'Missing "type" property on object'
				}
				return result
			}
		}
	},
	data: function () {
		return {
			showDialog: true,
			uniqueNumber: null
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		nodes: function () {
			return Array.isArray(this.value) ? this.value : [this.value] || []
		},
		node: function () {
			return this.nodes.length == 1 ? this.nodes[0] : null
		},
		canEditUniqueNumber: function () {
			return this.node?.folder_id && !this.isGuest && !this.node?.is_readonly && !this.node?.is_trash && (this.node?.is_folder || !this.node?.is_default)
		},
		parentFolder: function () {
			return this.model ? this.model.parent : null
		},
	},
	mounted: function () {
		this.appEventBus.emit(this.appEvents.PAUSE_LISTENING_TO_KEYBOARD)
		this.uniqueNumber = this.value.number
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.RESUME_LISTENING_TO_KEYBOARD)
		this.close()
	},
	methods: {
		onDialogReady () {
			this.$nextTick(() => {
				this.$refs.uniqueNumberInput.focus()
			})
		},
		close: function () {
			this.showDialog = false
			this.$emit('close')
		},
		save: function () {
			const updateAction = this.model.is_folder ? this.service.updateFolder : this.service.updateFolderDocument
			const folder_id = this.parentFolder ? this.parentFolder.id : this.model.folder_id
			updateAction(this.vendorId, folder_id, this.model, null, { number: this.uniqueNumber })
				.then(() => {
					this.model.number = this.uniqueNumber
					const message = this.value.is_folder ? 
						this.$tc('documents.folder_updated', this.nodes.length) : 
						this.$tc('documents.document_updated', this.nodes.length)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, message)
				})
				.finally(() => {
					this.close()
				})
		}
	}
}
</script>
